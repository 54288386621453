@font-face {
    font-family: magilio-regular;
    src: url(../fonts/Magilio-Regular.ttf);
}

@font-face{
    font-family:Roboto;
    src: url(../fonts/Roboto-Bold.ttf);
    font-weight: 700;
}
@font-face{
    font-family:Roboto;
    src: url(../fonts/Roboto-Medium.ttf);
    font-weight: 500;
}
@font-face{
    font-family:Roboto;
    src: url(../fonts/Roboto-Regular.ttf);
    font-weight: 400;
}
@font-face{
    font-family:Roboto;
    src: url(../fonts/Roboto-Light.ttf);
    font-weight: 300;
}
